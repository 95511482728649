import { BULLET_GRAPH_COLORS, VUETIFY_COLORS as DEFAULT_VUETIFY_COLORS, TIMESERIES_COLORS } from '../default/colors.js'
import { VuetifyColors } from '../types.js'

const VUETIFY_COLORS = {
  ...DEFAULT_VUETIFY_COLORS,
  anchor: '#003463',
  primary: {
    base: '#618097',
    darken2: '#003463',
    darken4: '#0C123B',
    lighten2: '#AEBFC9',
    lighten3: '#D8DFE3',
    lighten4: '#D8DFE3'
  }
} as VuetifyColors

const COCKPIT_NUDGE_GRADIENT = [
  '#003463',
  '#003463',
  '#003463'
] as string[]

export { BULLET_GRAPH_COLORS, COCKPIT_NUDGE_GRADIENT, TIMESERIES_COLORS, VUETIFY_COLORS }
